<ng-container *ngIf="apiStatus === 'restricted'; else dashboard">
  <h1>You are not authorized for this view</h1>
  <p>Contact your local KlinikPro superuser for more information.</p>
</ng-container>

<ng-template #dashboard>
  <section id="dashboard" [ngClass]="{ 'sidebar-open': sidebarOpen }">
    <div class="sidebar-container">
      <app-sidebar
        [activeView]="activeView"
        [activeChartgroup]="activeChartgroup"
        [views]="views"
        (emitChangeView)="switchView($event)"
        (emitChangeChartGroup)="switchChartgroup($event)"
        (emitFullscreen)="toggleSidebar($event)"
        (emitContactTypeUpdate)="handleContactTypeUpdate($event)"
        (emitServiceFilterUpdate)="handleServiceFilterUpdate($event)">
      </app-sidebar>
      <button (click)="toggleSidebar()">
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>

    <div class="view-container">
      <ng-container *ngIf="activeView === dailyView; else chart">
        <app-daily
          [activeChart]="activeChart"
          [activeView]="activeView"
          [dailyData]="dailyData"
          [sidebarOpen]="sidebarOpen"
          (linkedChartEmitter)="switchToLinkedChart($event)">
        </app-daily>
      </ng-container>

      <ng-template #chart>
        <app-chart
          [activeChart]="activeChart"
          [activeView]="activeView"
          [sidebarOpen]="sidebarOpen"
          (switchChartEmitter)="switchChart($event)"
          [chartDescription]="chartDescription">
        </app-chart>
      </ng-template>
    </div>
  </section>
</ng-template>
