// @ts-nocheck
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'services/utils.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnChanges {
  @Input()
  public activeChart: Chart;

  @Input()
  public activeView: string;

  @Input()
  public sidebarOpen = true;

  @Output()
  private switchChartEmitter: EventEmitter<object> = new EventEmitter();

  public chartDescription: string;
  public typeChart = CardTypes.CHART;
  public typeTotal = CardTypes.TOTAL;
  public typeTop = CardTypes.TOP;

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Set the description based on the activeChart input
    if (changes['activeChart']) {
      this.setChartDescription();
    }

    if (this.utilsService.ObjectHasKey(changes, 'sidebarOpen')) {
      setTimeout(() => (this.activeChart = { ...this.activeChart }), 300);
    }
  }

  setChartDescription(): void {
    if (this.activeChart.id === 'contacts-by-unit') {
      this.chartDescription = $localize`:@@dashboard_chart_description: Note that the dashboard data is updated every few minutes.`;
    } else {
      this.chartDescription = '';
    }
  }

  switchChart(chartObject: object): void {
    this.switchChartEmitter.emit(chartObject);
  }
}
