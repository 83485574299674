<div class="chart-container" [ngClass]="{ 'sidebar-open': sidebarOpen }">
  <app-card
    id="chart"
    [type]="typeChart"
    [chart]="activeChart"
    [activeView]="activeView"
    [hideFilters]="false"
    (newChartIdEmitter)="switchChart($event)">
  </app-card>
  <app-card
    id="table"
    [type]="typeTop"
    [chart]="activeChart"
    [activeView]="activeView"
    [hideTitle]="true"
    (newChartIdEmitter)="switchChart($event)">
  </app-card>
  <app-card
    id="total"
    [type]="typeTotal"
    [chart]="activeChart"
    [activeView]="activeView"
    [hideTitle]="true"
    (newChartIdEmitter)="switchChart($event)">
  </app-card>
  <div class="chart-description">
    <p>{{ chartDescription }}</p>
  </div>
</div>
